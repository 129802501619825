@import "@vars";

.picker {
  position: relative;
  cursor: pointer;
  background: $white;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.border {
    border-right: 1px solid $inactive;
  }
  &__btn {
    padding-left: 20px;
  }

  &.primary {
    color: $primary-color;
  }

  &__calendar {
    background: $white;
    position: absolute;
    z-index: 2;
    padding-right: 5px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 200px;
    height: 300px;
    border-radius: 16px;
    box-shadow: $shadow;

    display: flex;

    transition: opacity, visibility 0.3s;

    &.bottom {
      bottom: -5px;
      transform: translateY(100%);
    }

    &.top {
      top: -5px;
      transform: translateY(-100%);
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__list {
    overflow: auto;
    flex: 1;
    color: $dark;

    &::-webkit-scrollbar-track {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &--item {
      cursor: pointer;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      text-align: center;

      transition: all 0.3s;

      &:hover {
        background: $inactive;
      }

      &.active {
        background: $primary-color;
        color: $white;
      }
    }
  }

  b {
    text-transform: uppercase;
    margin-bottom: 4px;
    font-size: 14px;

    &.error {
      color: $danger;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;

    &.label {
      color: $gray;
    }
  }

  & > svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: center;
    transform: rotate(90deg) translateX(-50%);
  }
}

@media (max-width: 450px) {
  .picker {
    &__calendar {
      width: 100%;
    }
  }
}
