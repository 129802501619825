@import "@vars";

.createBook {
    margin: 34px -28px -20px;

    &__row {
        position: relative;
        height: 72px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        box-shadow: $shadow;

        &-swap {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }
    }

    &__mainRow {
        display: flex;
        width: 100%;
        margin-bottom: 20px;

        box-shadow: $shadow;

        &>* {
            box-shadow: unset;
            flex: 1;
            margin-bottom: 0;
        }
    }

    &__total {
        margin-top: -20px;
    }
}

@media (max-width: 1110px) {
    .createBook {
        margin: 34px -16px -8px;
    }

}

@media (max-width: 700px) {
    .createBook {
        margin: 34px 0 0;

        &__row {

            &.locations {
                height: unset;
                flex-direction: column;
                box-shadow: unset;
                align-items: flex-start;
                margin-bottom: 0;
            }

            &-swap {
                display: none;
            }

        }

        &__input {
            border-left: none !important;
        }

        &__picker {
            &:last-child {
                border-right: none;
            }
        }

        &__mainRow {
            box-shadow: unset;
            flex-direction: column;
            margin-bottom: 0;

            &>* {
                flex: unset;
                box-shadow: $shadow;
                margin-bottom: 20px;
            }
        }


        &__input {
            width: 100% !important;
            box-shadow: $shadow;
            margin-bottom: 20px;
            padding-left: 20px !important;
            height: 72px !important;
        }
    }
}