@import "@vars";

.popup {
    position: relative;
    width: fit-content;

    &:hover {
        .popup {
            &__box {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__box {
        padding: 12px 24px;
        position: absolute;
        z-index: 2;
        width: 236px;
        bottom: -12px;
        background-color: $white;
        transform: translateY(100%);
        box-shadow: $shadow;
        border-radius: 16px;

        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        color: $dark;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        white-space: wrap;

        &.center {
            left: 50%;
            transform: translate(-50%, 100%);
        }

        &.sm {
            width: 137px;
        }

        &.md {
            width: 343px;
        }

        &.textCenter {
            text-align: center;
        }



        &.left {
            left: 0;
        }
    }

    &__triangle {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $white transparent;



    }
}

@media (max-width: 1100px) {
    .popup {
        &__box {
            &.center {
                left: 0;
                transform: translate(0, 100%);
            }
        }

        &__triangle {
            &.center {
                left: 20px;
                transform: translate(0, -100%);
            }
        }
    }
}