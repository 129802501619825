@import "@vars";

.preferences {

    &__switch {
        border-bottom: 1px solid $inactive;
        padding: 8px 0;
    }

    &__list {
        flex-wrap: wrap;
        gap: 10px;
    }

    &__item {
        margin-bottom: 20px;
        width: 100%;

        display: flex;
        align-items: center;



        &>span {
            display: flex;
            align-items: center;
            background: $alabaster;
            border-radius: 20px;
            padding: 4px 10px;

            line-height: 24px;

            svg {
                cursor: pointer;
                margin-left: 10px;

                path {
                    fill: $inactive
                }
            }
        }
    }
}