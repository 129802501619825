@import "@vars";

.driver {
    position: relative;

    &__btn {
        cursor: pointer;
        font-size: 14px;
        color: $gray;
        transform: all .3s;
        padding: 0 10px;
        margin: 0 -10px;
        line-height: 24px;
        height: 32px;
        width: fit-content;

        display: flex;
        align-items: center;

        &:hover {
            color: $primary-color;
            background: $alabaster;
            border-radius: 12px;
        }
    }

    &__list {
        position: absolute;
        bottom: 0;
        left: 0;

        transform: translateY(100%);
    }

    &__empty {
        background: $alabaster;
        z-index: 3;
        padding: 16px;
        line-height: 22px;
        text-align: center;
        border-radius: 16px;
        box-shadow: $shadow;
        width: 250px;
        text-transform: initial;

        span {
            text-transform: capitalize;
        }
    }
}