@import "./shared/variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: $white;
  font-family: $primary-font;
  font-weight: 400;
  color: $dark;
  font-size: 16px;
}

b {
  font-weight: 700;
}

h1 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;

  &.center {
    text-align: center;
  }
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1750px;
  padding: 0 $containerPadding;
  margin: 0 auto;
}

.signup-container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: $iron;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: $lightGray;
  border-radius: 4px;
}

::-webkit-scrollbar-button {
  display: none;
}

button,
input {
  border: none;
  outline: none;
}

.infinite-scroll-component {
  overflow: initial !important;
}
input {
  font-family: $primary-font;
  color: $dark;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

@media (max-width: 1300px) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: 1100px) {
  .container {
    padding: 0 20px;
  }
}
