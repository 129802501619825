@import "@vars";

.setPay {
    padding-top: 94px !important;

    &__title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    &__switch {
        margin-bottom: 20px;
    }

    &__bar {
        border-bottom: 1px solid $gray;
        margin-bottom: 34px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__check {
        width: 50%;
        margin-bottom: 18px;
    }

    &__btn {
        text-transform: uppercase;
    }
}