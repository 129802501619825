@import "@vars";

.details {
    max-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;

    &__heading {
        color: $lightGray;
        padding-bottom: 12px;
        border-bottom: 1px solid $gray;
    }

    &__scroll {
        flex: 1;
        max-height: 578px;
        overflow: auto;
        padding: 12px 10px 0 0;

        margin-right: -10px;
        margin-bottom: 20px;

        &::-webkit-scrollbar-track {
            margin: 12px 0;
        }
    }

    &__date {
        line-height: 22px;
        font-weight: 700;
        color: $primary-color;
        border-bottom: 1px solid $gray-stroke;
        padding: 6px 0;
        margin-bottom: 6px;
    }

    h1 {
        margin-bottom: 16px;
    }

    &__filter {
        margin-bottom: 16px;
    }

    &__footing {

        &.border {
            border-top: 1px solid $gray;
            padding-top: 20px;
        }
    }

    &__cancel {
        margin-top: 13px;
    }

    &__total {
        display: flex;
        justify-content: space-between;
        line-height: 19px;
        font-size: 18px;

        &-pre {
            color: $gray-stroke;
            text-decoration: line-through;
            margin-right: 12px;
        }

        &.gray {
            color: $gray;

            b:first-child {
                color: $lightGray;
            }
        }

        &.border {
            border-top: 1px solid $gray;
            padding-top: 20px;
            margin-top: 20px;
        }
    }

    &__btn {
        margin-top: 13px;
    }

    &__error {
        padding: 10px;
        font-size: 16px;
        line-height: 24px;
        background: $alabaster;
        margin-top: 13px;
        border-radius: 12px;
        margin-bottom: 20px;

        span {
            cursor: pointer;
            font-weight: 700;
            text-decoration: underline;
        }
    }

    &__earn {
        height: 44px;
        background: $alabaster;
        margin-top: 13px;

        display: flex;
        align-items: center;
        justify-content: center;

        &.white {
            background: $white;
            box-shadow: $shadow;
        }


    }

    &__coupon {
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: $shadow;
        padding: 10px 20px;
        margin: 19px 0 13px;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;

        &-price {
            font-size: 22px;
        }

        &-check,
        &-delete {
            width: 16px;
            height: 16px;
        }

        &-delete {
            cursor: pointer;

            path {
                fill: $white;

            }

            rect {
                fill: $inactive;
            }
        }
    }
}

.booking {
    line-height: 22px;
    font-weight: 300;


    &:not(:last-child) {
        margin-bottom: 30px;

    }

    &__title {
        text-decoration: underline;
    }

    &__list {
        margin-top: 12px;

        &-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            span {
                &.error {
                    color: $danger;
                    font-weight: 600;
                }
            }
        }
    }
}