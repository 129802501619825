.settings {
    &__input {
        margin-bottom: 20px;
        flex: 1;
    }

    &__row {
        display: flex;
        gap: 20px;
    }
}