@import "@vars";

.terms {

    span,
    p,
    b {
        display: block;
        margin-bottom: 16px;
    }

    &__suptitle {
        font-size: 14px;
        color: $lightGray;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $lightGray;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 16px;
    }
}

.list {


    & li {
        display: table;

        &::before {
            display: table-cell;
            padding-right: 6px;
        }
    }



    &.decimal {
        list-style: none;
        counter-reset: item;

        &>li {
            counter-increment: item;

            &::before {
                content: counters(item, ".") ". ";
                font-weight: bold;
            }
        }
    }

    &.lower-alpha {
        list-style-type: none;
        counter-reset: letter-counter;

        & li {
            &::before {
                counter-increment: letter-counter;
                content: " (" counter(letter-counter, lower-alpha) ") ";
                font-weight: 400;
            }
        }
    }

    &.upper-roman {
        list-style-type: none;
        counter-reset: roman-counter;

        & li {

            &::before {
                counter-increment: roman-counter;
                content: " (" counter(roman-counter, upper-roman) ") ";
                font-weight: 400;
            }
        }
    }

    &.upper-alpha {
        list-style-type: none;
        counter-reset: upper-letter-counter;

        & li {

            &::before {
                counter-increment: upper-letter-counter;
                content: " (" counter(upper-letter-counter, upper-alpha) ") ";
                font-weight: 400;
            }
        }
    }



}