@import "@vars";

.booking {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 494px;
    margin: 0 auto;

    &__type {
        margin-bottom: 20px;
        padding: 0 30px;
        border-radius: 30px;
        height: 20px;

        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;

        display: flex;
        align-items: center;

        &.upcoming {
            background: $success;
        }

        &.done {
            background: $inactive;
        }

        &.suspended {
            background: $warning;
        }

        &.canceled {
            border: 1px solid $dark;
        }
    }

    &__number {
        font-size: 27px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    &__row {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        width: 100%;

        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        &.bold {
            font-weight: 700;
        }

        &-title {
            color: $lightGray;
        }

        &-value {
            text-align: right;

            p:not(:last-child) {
                margin-bottom: 12px;
            }

            &.pending {
                color: $danger;
            }

            &.name {
                text-transform: capitalize;
            }
        }

        &-popup {
            color: $dark;
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__bar {
        margin: 20px 0;
        width: 100%;
        height: 1.7px;
        background-image: linear-gradient(to right, $inactive 50%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 12px 1.7px;
        background-repeat: repeat-x;
    }

    &__info {
        width: 100%;
        padding: 16px;
        border-radius: 16px;
        background: $alabaster;

        line-height: 22px;

    }

    &__download {
        align-self: flex-end;
        display: flex;
        align-items: center;
        cursor: pointer;

        font-size: 18px;
        font-weight: 300;

        &.disabled {
            pointer-events: none;
        }

        svg {
            margin-right: 14px;

            path {
                stroke: $dark;
            }
        }
    }

    &__btns {
        margin-top: 28px;
        width: 100%;
        display: flex;
        gap: 14px;
    }
}