@import "@vars";

.card {
  background: $white;
  width: 100%;
  box-shadow: $shadow;
  border-radius: 12px;
  padding: 28px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--bar {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      background: $primary-color;
      border-radius: 12px 0 0 12px;
    }
  }
}
@media (max-width: 1110px) {
  .card{
    padding: 16px;
  }
}