@import "@vars";

.dots {
    width: 15px;

    &::before {
        content: '.';
        animation: dark-dots 1s steps(5, end) infinite;
    }
}

@keyframes dark-dots {

    0%,
    20% {
        color: $white;
        text-shadow:
            .25em 0 0 rgba($color: $white, $alpha: 0),
            .5em 0 0 rgba($color: $white, $alpha: 0);
    }

    40% {
        color: $dark;
        text-shadow:
            .25em 0 0 rgba($color: $white, $alpha: 0),
            .5em 0 0 rgba($color: $white, $alpha: 0);
    }

    60% {
        text-shadow:
            .25em 0 0 $dark,
            .5em 0 0 rgba($color: $white, $alpha: 0);
    }

    80%,
    100% {
        text-shadow:
            .25em 0 0 $dark,
            .5em 0 0 $dark;
    }
}