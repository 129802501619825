@import "@vars";

.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: $pageHeadedingMargin;
    .title{
        display: flex;
        align-items: center;
        gap: 20px;
        div{
            padding: 8px;
            display: none;
            border-radius: 16px;
            background:#F0FAF6;
        }
    }
    h1 {
        margin-right: 12px;
    }

    &__search {

        background: $inactive !important;
        width: 32px;
        height: 32px !important;
        padding: 0 !important;
        border-radius: 12px !important;

        justify-content: center;

        svg {
            margin-right: 0 !important;
        }
    }

    &__btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        background: $inactive;
        width: 32px;
        height: 32px;
        border-radius: 12px;
        margin-right: 20px;

    }

    &__input {
        width: 200px;
        height: 32px;
    }

}
@media (max-width:1100px) {
    .header{
        .title{
            
            div{
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}