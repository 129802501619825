@import "@vars";

.table {
  &__row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $gray;
    margin-bottom: 10px;

    &.md {
      gap: 50px;
    }

    &.sm {
      gap: 10px;
    }

    &-item {
      line-height: 24px;
      padding: 6px 0;
      flex: 1;
      text-transform: capitalize;

      &.action {
        display: flex;
        align-items: center;
        flex: 0;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.primary {
        color: $primary-color;
      }

      &--add {
        font-size: 14px;
        line-height: 20px;
        color: $lightGray;
      }
    }
  }
  .header {
    background-color: $gray-stroke;
    padding: 6px 10px;
    border-radius: 20px;
    p {
      color: $lightGray;
    }
  }
  &__btn {
    cursor: pointer;
    font-size: 14px;
    color: $gray;
    transform: all 0.3s;
    padding: 0 10px;
    margin: 0 -10px;
    line-height: 24px;
    height: 32px;
    width: fit-content;

    display: flex;
    align-items: center;

    &:hover {
      color: $primary-color;
      background: $alabaster;
      border-radius: 12px;
    }
  }
}
