@import "@vars";

.spinner {
    height: 32px;
    width: 32px;
    -webkit-animation: spinner 4.8s linear infinite;
    animation: spinner 4.8s linear infinite;

    &.sm {
        height: 16px;
        width: 16px;

        & span {
            height: 16px;
            width: 16px;
            clip: rect(0, 16px, 16px, 8px);

            &::after {
                height: 16px;
                width: 16px;
                clip: rect(0, 16px, 16px, 8px);
                border-width: 2px;
            }
        }
    }

    &.primary {
        & span {
            &::after {
                border-color: $primary-color;
            }
        }
    }

    & span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        clip: rect(0, 32px, 32px, 16px);
        -webkit-animation: spinner-sicrlce 1.2s linear infinite;
        animation: spinner-sicrlce 1.2s linear infinite;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 32px;
            width: 32px;
            clip: rect(0, 32px, 32px, 16px);
            border: 3px solid #FFF;
            border-radius: 50%;
            -webkit-animation: spinner-circle-after 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
            animation: spinner-circle-after 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
        }
    }
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@-webkit-keyframes spinner-sicrlce {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(220deg);
    }
}

@keyframes spinner-sicrlce {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(220deg);
    }
}


@-webkit-keyframes spinner-circle-after {
    0% {
        -webkit-transform: rotate(-140deg);
    }

    50% {
        -webkit-transform: rotate(-160deg);
    }

    100% {
        -webkit-transform: rotate(140deg);
    }
}

@keyframes spinner-circle-after {
    0% {
        transform: rotate(-140deg);
    }

    50% {
        transform: rotate(-160deg);
    }

    100% {
        transform: rotate(140deg);
    }
}