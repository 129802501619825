@import "@vars";

.select {
    position: relative;
    display: inline-block;
    width: 100%;

    &__btn {
        cursor: pointer;

        display: flex;
        align-items: center;
        height: 34px;
        text-decoration: underline;
        background: $white;
        border-radius: 20px;
        padding: 0 16px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 26px;

        svg {
            margin-right: 8px;
        }
    }

    &__popup {
        max-height: 0;
        overflow: hidden;
        position: absolute;
        bottom: -2px;
        transform: translateY(100%);
        z-index: 3;

        width: 250px;
        border-radius: 16px;
        box-shadow: $shadow;
        background: $white;

        display: flex;
        flex-direction: column;

        transition: all 0.3s;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &.active {
            max-height: 448px;

        }

        &.static {
            position: relative;
            max-height: 48px !important;
            overflow: visible;
            box-shadow: unset;
            transform: none;
            height: 36px;
            width: 100%;

            & .select {
                &__search {
                    height: 100%;
                }

                &__list {

                    background: $white;
                    box-shadow: $shadow;
                    border-radius: 12px;
                    width: 100%;
                    height: 200px;
                    bottom: -2px;
                    transform: translateY(100%);

                    position: absolute;
                }
            }
        }
    }

    &__list {
        overflow: auto;
        height: 100%;
        flex: 1 1 auto;

        &::-webkit-scrollbar-track {
            margin: 12px 0;
        }

        &-item {
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;
            text-transform: capitalize;

            transition: all 0.3s;

            &:hover {
                background: $alabaster;
            }
        }
    }

    &__search {
        position: relative;
        padding: 0 16px;

        &.border {
            input {
                border-color: $gray;
            }
        }

        input {
            width: 100%;
            border: none;
            outline: none;
            height: 48px;
            border-bottom: 1px solid #fff;
            padding: 0 36px;
            color: $lightGray;
            font-size: 16px;
        }

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.search {
                left: 16px;
            }

            &.arrow {
                cursor: pointer;
                right: 20px;
            }
            &.clear{
                right: 0px;

            }
        }
    }
}