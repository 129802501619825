@import "@vars";

.driver {
    position: relative;

    &__btn {
        cursor: pointer;
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        color: $primary-color;
    }

    &__list {
        position: absolute;
        top: 0;
        left: 0;

        transform: translateY(-100%);
    }
}