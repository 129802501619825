@import "@vars";

.confirm-container {
  width: 232px;
  line-height: 56px;
  min-height: 50px;
  margin-left: auto;
  margin-right: auto;
}
.character {
  font-size: 30px;
  border: none;
  font-family: "montserrat-regular", sans-serif;
  border-bottom: 1px solid #d8ddea;
}
.character--selected {
  color: #12bf66;
  outline: #12bf66;
  border-bottom: 1px solid #12bf66;
}

.character--inactive {
  background-color: transparent;
}
