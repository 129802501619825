@import "@vars";

.code {
    padding: 34px 30px;
    text-align: center;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    font-family: "montserrat", sans-serif;
    padding-top: 30px;

    h2 {
        margin-top: 18px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 800;
    }
    // font-size: 26px;
    p {
        font-size: 18px;
        line-height: 26px;
        margin: 20px 0;
    }

    &__wrapper {
        width: fit-content;
        max-width: 1016px;
        margin: 0 auto;
    }

    &__buttons {
        margin-top: 20px;
        display: flex;

        &--item {
            flex: 1;
            margin: 0 10px;
        }
    }

    .active {
        cursor: pointer;
    }
}
