@import "@vars";

.cancel {
    width: 1016px;
    font-size: 14px;
    font-weight: 400;
    text-align: start;

    &__select {
        margin-bottom: 20px;
        display: block;
    }

    &__box {
        width: 505px;
    }

    &__table {
        margin-bottom: 20px;
        margin-top: 34px;

        border-bottom: 1px solid $gray;
    }
}



.admin {
    margin: 20px 0;
    background: $crm-background;
    padding: 28px;
    border-radius: 12px;

    &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &-avatar {
            min-width: 36px;
            height: 36px;
            border-radius: 50%;
            background: $dark;
            margin-right: 16px;
            font-size: 20px;
            line-height: 24px;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            width: 100%;
            height: 56px;
            padding: 0 16px;
            font-size: 18px;
            border-radius: 12px;
            box-shadow: $shadow;
        }
    }

    &__error {
        color: $danger;
        margin-bottom: 16px;
    }
}

.mobile_action_buttons {
    display: none;
}

@media (max-width: 1100px) {
    .cancel {
        width: 340px;

        &__table {
            display: none;
        }

        &__box {
            display: none;
        }

        .admin_comment {
            display: none;
        }

        .admin {
            display: none;
        }

        .mobile_action_buttons {
            display: flex;
            gap: 8.5px;
        }

        .action_button {
            display: none;
        }
    }
}