@import "@vars";

.stepper {
    margin: 24px 0;
    display: flex;
    gap: 20px;

    &__hide {
        display: none;
    }
}

.step {
    flex: 1;

    &__heading {
        display: flex;
        align-items: center;
        margin-bottom: 13px;


        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: $gray;
            color: $white;
            font-weight: 500;
            margin-right: 10px;
        }

        &-bar {
            height: 2px;
            flex: 1;
            background: $gray;
        }

        &.active {
            cursor: pointer;

            span,
            .step__heading-bar {
                background: $primary-color;
            }

        }
    }

    &__supTitle {
        font-size: 10px;
        color: $gray;
        margin-bottom: 2px;
    }

    &__title {
        font-size: 14px;
        line-height: 20px;
    }
}

@media (max-width: 700px) {
    .stepper {
        padding-bottom: 40px;
    }

    .step {

        &__supTitle {
            display: none;
        }

        &__title {

            display: none;
            font-size: 18px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);

            &.active {

                display: block;
            }
        }

        &__heading {
            &:not(.active) {
                & .step {
                    &__title {
                        display: none;
                    }
                }
            }
        }
    }

}