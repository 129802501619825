@import "@vars";

.repeat {
    &__wrapper {
        max-width: 520px;
        margin: 0 auto;
    }

    &__calendar {
        margin-bottom: 24px;
    }

    &__title {
        text-align: center;
        margin-bottom: 32px;

    }

    &__list {
        margin-bottom: 14px;
    }


}

.booking {
    margin-bottom: 30px;

    &__title {
        text-align: start;
        margin-bottom: 12px;

        &.active {
            color: $primary-color;
        }
    }

    &__row {
        box-shadow: $shadow;
        display: flex;
        align-items: center;
        margin-top: 20px;

        &-item {
            height: 72px !important;

            &.first {
                flex: 1.3 !important;
            }

            &.no-border {
                border-right: none;
            }
        }

        &-btn {
            position: relative;
            cursor: pointer;
            height: 100%;
            padding: 0 20px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                width: 1px;
                height: 20px;
                top: 50%;
                transform: translateY(-50%);
                background: $dark;
            }
        }
    }

    p {
        line-height: 22px;
    }
}