@import "@vars";

.input {
  width: 100%;
  padding: 8px 0;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid $inactive;

  &__wrapper {
    position: relative;
    .flex {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 8px;

      color: $gray;
    }
  }

  &__eye {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: -5px;
    transform: translateY(100%);
    color: $danger;
    font-size: 10px;
  }

  &::placeholder {
    color: $gray;
    font-weight: 300;
  }
}
