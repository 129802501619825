@import "@vars";

.status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 28px 36px !important;

    &__title {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    &__check {
        cursor: pointer;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(35, 162, 109, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        svg {
            width: 50px;
            height: 50px;
        }
    }

    &__info {
        background: $alabaster;
        border-radius: 16px;
        text-align: center;
        width: 100%;
        padding: 16px;
    }
}