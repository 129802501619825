@import "@vars";

.booking {
  line-height: 22px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__date {
    margin-bottom: 12px;
  }

  &__driver {
    margin: 12px 0;
  }

  &__button {
    margin-left: 6px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  b {
    text-transform: capitalize;
  }

  &__btn {
    margin: 6px 0;
  }
  .status {
    display: flex;
    gap: 15px;
    
  }
}

.error {
  color: $danger;
}

@media (max-width: 1110px) {
  .booking {
    &__row {
      &-box {
        max-width: 199px;
      }
    }
  }
}
