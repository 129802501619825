@import "@vars";

.container {
    display: flex;
    justify-content: space-between;

    .add {
        &:focus {
            outline: none;
        }
    }
}
