@import "@vars";

.container {
  display: none;
}
@media (max-width: 1100px) {
  .container {
    display: flex;
    margin-bottom: 20px;
    .list {
      flex: 1;
      &__item {
        padding: 5px 0;
        border-bottom: 1px solid #b0b0b0;
        display: flex;
        justify-content: space-between;
        &__name {
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          gap: 7px;
        }
        &__phone {
          color: $lightGray;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }
        &__set_driver {
          color: $primary-color;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          text-align: end;
        }
        &__card {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: $primary-color;
        }
        &__count {
          font-size: 14px;
          line-height: 24px;
        }
      }
      &__clear {
        path {
          fill: $inactive;
        }
      }
    }
    .set_driver_container {
      .prefered_driver {
        margin-bottom: 18px;
      }
      .chip_wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 19px;
        gap: 30px;
      }
      .btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 4px 10px;
        width: fit-content;
        background: $alabaster;
        transition: all 0.3s;
        svg {
          opacity: 1;
          margin-left: 6px;
          transition: all 0.3s;
        }
      }
    }
  }
  .divider {
    border-bottom: 1px solid #b0b0b0;
    margin-top: 19px;
    margin-bottom: 30px;
  }
}
