@import "@vars";

.customer {
  &__form {
    &-row {
      display: flex;
      gap: 20px;
    }
    .select {
      margin-top: 15px;
      position: relative;
      height: 72px;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      box-shadow: $shadow;
    }
    &-input {
      flex: 1;
      margin-bottom: 20px;
    }
    &-check {
      margin: 15px 0;
    }
  }

  &__button {
    display: block;
    margin-left: auto;
  }
  &__mptp {
    margin-bottom: 20px;
  }
}

.plus_btn {
  width: 44px;
  border-left: 1px solid $dark;
  cursor: pointer;
  margin-left: 10px;
}
