@import "@vars";

.burger {
  display: none;
}

@media (max-width: 1130px) {
  .burger {
    display: block;
    position: relative;
  }

  .overlay {
    background-color: inherit !important;
  }

  .btn {
    cursor: pointer;

    span {
      display: block;
      width: 18px;
      height: 2px;
      background: $dark;
      border-radius: 2px;
      margin: 3px 0;
    }
  }

  .menu_container {
    background-color: $white;
    width: 265px;
    height: 100%;
    position: absolute;
    top: -48px;
    left: -20px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12);
  }

  .logout {
    cursor: pointer;
  }

  .profile {
    box-shadow: $shadow;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px 27px;
    gap: 11.5px;
    background: $primary-color;
    height: 74px;
    width: 265px;

    .name {
      color: $white;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $white;
      margin-left: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .menu {
    padding: 17px 27px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid $gray-stroke;

    & a {
      display: flex;
      align-items: center;
      padding: 4px 16px;
      height: 30px;
      font-size: 12px;
      line-height: 26px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      color: $dark;
      border-radius: 20px;
      transition: all 0.3s;
      width: fit-content;

      svg {
        margin-right: 8px;
      }

      &.active {
        color: $white;
        background: $dark;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    padding-top: 40px;

    a {
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
      color: $dark;
      text-transform: uppercase;

      padding: 4px 16px;
      margin-bottom: 20px;
    }
  }

  .link {
    text-decoration: underline;
    color: $lightGray;
    font-size: 14px;
    margin-bottom: 14px;
  }
}
