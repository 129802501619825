@import "@vars";

.header {
  min-height: 56px;
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  &__search {
    margin-left: 16px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    background: $primary-color;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: $white;
      }
    }
  }

  &__input-block {
    margin-left: 21px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  &__arrow {
    width: 56px;
    height: 56px;
    border-radius: 12px;
    background: $primary-color;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: $white;
      }
    }
  }
}

.search-input {
  height: 56px;
  border-radius: 16px;
  box-shadow: 0px 4px 40px 0px #00000017;
  padding: 19px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  input {
    min-width: 256px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  input::placeholder {
    color: $lightGray;
  }

  &__search {
    path {
      fill: $lightGray;
    }
  }
  &__clear {
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .header {
    min-height: 32px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    gap: 10px;

    &__input-block {
      margin-left: 0px;
    }
    .search-input {
      padding: 10px;
      gap: 8px;
      input {
        min-width: 60vw;
        max-width: 60vw;
      }
    }
    &__arrow {
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width:375px) {
  .header{
    .search-input{
      input{
        min-width: 157px;
        max-width: 157px;
      }
    }
  }
}