@import "@vars";

.list {
  overflow: hidden;
  max-height: 428px;
  width: 250px;
  border-radius: 16px;
  box-shadow: $shadow;
  background: $white;

  display: flex;
  flex-direction: column;

  &__empty {
    text-align: center;
    padding: 10px 16px;
  }

  &.popup {
    position: relative;
    overflow: visible;
    max-height: unset;

    height: 64px;
    width: 520px;

    & input {
      border: none;
      height: 100%;
      padding-left: 0;
    }

    & span.arrow {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .list__search {
      height: 100%;
    }
  }

  &__box {
    overflow: auto;
    height: 100%;
    flex: 1 1 auto;

    &::-webkit-scrollbar-track {
      margin: 12px 0;
    }

    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 16px;

      transition: all 0.3s;

      &:hover {
        background: $iron;
      }
    }

    &.popup {
      position: absolute;
      height: unset;
      left: 0;
      right: 0;
      bottom: -5px;
      transform: translateY(100%);
      z-index: 1;
      max-height: 0;
      background: $white;
      border-radius: 16px;
      box-shadow: $shadow;

      &.focused {
        max-height: 428px;
      }
    }
  }

  &__search {
    position: relative;
    padding: 0 16px;
    padding-left: 50px;

    &.border {
      input {
        border-color: $gray;
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      height: 48px;
      border-bottom: 1px solid #fff;
      padding: 0 36px;
      color: $lightGray;
      font-size: 16px;

      &::placeholder {
        color: $gray;
      }
    }

    svg,
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.search {
        left: 16px;
      }

      &.arrow {
        cursor: pointer;
        right: 30px;
      }
    }
  }
}

@media (max-width: 1110px) {
  .list {
    &.popup {
      width: 90vw;
    }
  }
  .empty {
    background: #f0faf6;
    padding: 16px;
    line-height: 22px;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);
    text-transform: initial;
  }
}
