@import "@vars";

.container {
    flex-direction: column;
    display: flex;
    

    .table {
        display: block;
    }

    div.header {
        justify-content: space-between;
        display: flex;
        div {
            flex: 1;
            color: $gray;
            line-height: 44px;
        }
        border-bottom: 1px solid $black;
        padding-bottom: 8px;
    }

    .row:hover {
        background-color: #f6f6fb;
        cursor: pointer;
    }

    div.row {
        div {
            flex: 1;
        }
        justify-content: space-between;
        display: flex;
        
        line-height: 52px;
        padding: 0px 2px 0px 2px;
    }

    .close {
        display: flex;
        cursor: pointer;
        justify-content: end;
        align-items: center;

        .icon {
            width: 16px;
            height: 16px;
            &:hover {
                path {
                    fill: $primary-color;
                }
            }
            path {
                fill: $gray-stroke;
            }
        }
    }
}


