@import "@vars";
.container {
  display: none;
}
.loading {
  margin-top: 6px;
  &__flex {
    display: flex;
    justify-content: space-between;
    margin: 6px 0;
  }
  border-bottom: 1px solid #b0b0b0;
}
@media (max-width: 1100px) {
  .container {
    display: block;
    .row {
      padding-top: 6px;
      border-bottom: 1px solid #b0b0b0;
      &__date {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 6px;
      }
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__item-invoice {
        color: $primary-color;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
      }
      &__item-cost {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
