@import "@vars";

.select {
  position: relative;
  display: inline-block;

  &.xl {
    width: 505px;
    height: 48px;
  }

  &.full {
    width: 100%;
    height: 56px;

    & .select {
      &__btn {
        height: 56px;
      }
    }
  }

  &.sm {
    width: 122px;
  }


  &.neutral {
    .select {
      &__btn {
        background: $alabaster;
        box-shadow: none;

        svg {
          path {
            fill: $primary-color;
          }
        }
      }
    }
  }

  &.ghost {
    width: 36px;
    height: 50px;

    & .select {

      &__btn {
        background: none;
        box-shadow: none;
      }
    }
  }

  &__btn {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 48px;
    background: $white;
    box-shadow: $shadow;
    border-radius: 12px;
    font-size: 18px;

    display: flex;
    align-items: center;
    padding-left: 13px;

    color: $lightGray;

    & input {
      width: 100%;
      height: 100%;
      font-size: 18px;
      border-radius: inherit;

    }

    &.active {
      color: $dark;
    }

    &.show {
      svg {
        transform: rotate(270deg);
      }
    }



    svg {
      width: 24px;
      height: 30px;
      position: absolute;
      right: 10px;
      top: 10px;
      transform: rotate(90deg);

      transition: all .3s;
    }
  }


  &__popup {
    max-height: 0;
    overflow: hidden;
    position: absolute;
    bottom: -2px;
    transform: translateY(100%);
    right: 0;
    z-index: 3;

    width: 100%;
    min-width: 100px;
    border-radius: 16px;
    box-shadow: $shadow;
    background: $white;

    display: flex;
    flex-direction: column;

    transition: all 0.3s;

    &.show {
      max-height: 448px;
    }
  }

  &__list {
    overflow: auto;
    height: 100%;
    flex: 1 1 auto;

    &::-webkit-scrollbar-track {
      margin: 12px 0;
    }

    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 16px;

      transition: all 0.3s;

      &:hover {
        background: $iron;
      }
    }
  }


}