@import "@vars";

.container {
  padding: 28px;
  border-radius: 12px;
  border: 0px solid #000;
  background: $white;
  box-shadow: $shadow;
  margin-bottom: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
  .footer {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
}
