@import "./fonts.scss";
// fonts
$primary-font: "sf-pro", sans-serif;

// colors
$background-color: #f2f2f2;

$dark: #333333;
$primary-color: #12bf66;

$black: #000;
$white: #fff;
$alabaster: #f0faf6;
$iron: #d8ddea;
$lightGray: #6f7880;
$gray: #b0b0b0;
$inactive: #ddd;
$gray-stroke: #C6CBD4;
$crm-background: #F6F6FB;

$danger: #ff006e;
$success: #23e377;
$warning: #f9cb42;
$warning-info: #FFEEF1;


// gradient

$grass-gradient: radial-gradient(100% 12158.24% at 99.42% 0%,
        #00b45a 0%,
        #21c973 48.44%,
        #21c973 100%);

// shadow

$shadow: 0px 4px 40px rgba(0, 0, 0, 0.12);

$containerPadding: 75px;
$pageHeadedingHeight: 32px;
$pageHeadedingMargin: 20px;