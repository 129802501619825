@import "@vars";

.details {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: $primary-color;
    padding: 20px 28px 12px;
    border-radius: 20px 20px 0 0;



    &__header {
        border-bottom: 0.5px solid $gray-stroke;
        display: flex;
        justify-content: space-between;
        padding-bottom: 18px;
        align-items: center;

        &-title {
            color: $white;
            font-size: 22px;
            font-weight: 600;
        }

        &-btn {
            width: 35px;
            height: 35px;

            path {
                fill: $white;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        padding: 6px 0;

        color: $white;
        font-weight: 700;
    }

    &__main {
        max-height: unset;
        height: 100vh;
        box-shadow: none;
        flex: 1;
        margin-bottom: 28px;
    }

    &__scroll {
        max-height: unset;

        overflow: visible;
    }


    &__modal {
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        transition: all .3s;
        border-radius: 20px 20px 0 0;
        overflow: auto;
        box-shadow: $shadow;
        background: $white;

        z-index: 10;

        transform: translateY(100%);

        flex-direction: column;


        &.show {
            transform: translateY(0);
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &-btn {
            display: flex;
            justify-content: center;
            padding: 14px 0;

            span {
                width: 50px;
                height: 7px;
                background: $inactive;
                border-radius: 12px;
            }
        }
    }
}


@media (max-width: 1100px) {
    .details {
        display: block;

        &__modal {
            display: flex;
        }
    }

}