@import "@vars";

.filter {
    display: flex;

    &.show {
        .popup {
            opacity: 1;
            visibility: visible;


        }
    }

    &__item {
        height: 32px;
        background: $primary-color;
        border-radius: 30px;
        color: $white;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
    }

    &__btn {
        position: relative;
        cursor: pointer;
        margin-left: 10px;
        background: $inactive;
        width: 32px;
        height: 32px;
        border-radius: 12px;

        display: flex;
        align-items: center;
        justify-content: center;

        &.active {

            &>svg {
                rect {
                    fill: $primary-color;
                }

                path {
                    fill: $white;
                }
            }
        }

        &>svg {
            transition: all .3s;
        }
    }

    &__clear {
        height: 32px;
        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
        margin-left: 12px;
        display: flex;
        align-items: center;
    }
}

.popup {
    padding: 16px;
    position: absolute;
    top: -16px;
    right: -16px;
    transform: translateX(100%);
    box-shadow: $shadow;
    background: $white;
    border-radius: 16px;
    z-index: 2;

    display: flex;
    flex-direction: column;

    opacity: 0;
    visibility: hidden;

    transition: opacity, visibility .3s;

    &__list {
        margin-bottom: 0;
    }

    &__row {
        position: relative;
        z-index: 1;
        height: 58px;
        filter: drop-shadow($shadow);
        margin-top: 20px;
        display: flex;
        align-items: center;

        &--item {
            position: relative;
            cursor: pointer;
            background: $white;
            height: 100%;
            flex: 1;
            border-right: 1px solid $inactive;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:hover {
                & .popup {
                    &__calendar {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            b {
                text-transform: uppercase;
                margin-bottom: 4px;
                font-size: 14px;
            }

            p {
                font-size: 18px;
                font-weight: 300;
            }

            &>svg {
                position: absolute;
                right: 12px;
                top: 50%;
                transform-origin: center;
                transform: rotate(90deg) translateX(-50%);
            }
        }
    }


    &__input {
        margin-top: 13px;
        position: relative;
        padding: 0 16px;
        height: 48px;

        input {
            width: 100%;
            height: 100%;
            padding-left: 36px;
            border-bottom: 1px solid $gray;
        }
    }

    &__searchSvg,
    &__arrowSvg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__searchSvg {
        left: 16px;
    }

    &__arrowSvg {
        right: 16px;
    }

    &__search {
        cursor: pointer;
        background: $white;
        height: 100%;
        min-width: 65px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__select {
        background: $white;
        padding-left: 20px;
        border: 0 !important;
        border-right: 1px solid $gray-stroke !important;

        &:not(:first-child) {
            padding-left: 20px;
        }
    }

    &__clear {
        align-self: center;
        margin-top: 10px;
    }

    &__calendar {
        background: $white;
        position: absolute;
        bottom: -5px;
        left: 0;
        transform: translateY(100%);
        opacity: 0;
        visibility: hidden;
        width: 300px;

        transition: all .3s;
    }
}

@media (max-width:1300px) {
    .popup {
        right: unset;
        left: -8px;
        transform: translateX(-100%);
    }
}

@media (max-width:900px) {
    .filter {
        flex-direction: row-reverse;
    }

    .popup {
        &__row {
            &-item {
                &--popup {
                    left: 50% !important;
                    transform: translate(-50%, 100%) !important;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .filter {
        display: flex;
        flex-direction: row-reverse;

        &__clear {
            height: auto;
        }
    }

    .popup {
        width: calc(100vw - 40px);
        left: unset;
        height: auto;
        transform: none;
        top: -4px;
        right: 0;

        &__row {
            flex-direction: column;
            width: 100%;
            align-items: normal;
            height: auto;

            &-item {
                padding: 11px 0;

                &--popup {
                    left: 0 !important;
                    transform: translate(0, 100%) !important;
                }
            }
        }

        &__list {
            &-item {
                padding: 0 16px;
                font-size: 12px;
            }
        }

        &__search {
            height: auto;
        }
    }
}