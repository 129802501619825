@font-face {
  font-family: "sf-pro";
  src: url("../../assets/fonts/sf-pro-display/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "sf-pro";
  src: url("../../assets/fonts/sf-pro-display/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "sf-pro";
  src: url("../../assets/fonts/sf-pro-display/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "sf-pro";
  src: url("../../assets/fonts/sf-pro-display/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "sf-pro";
  src: url("../../assets/fonts/sf-pro-display/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: normal;
}