@import "@vars";

.agent {
  &__form {
    &-input {
      flex: 1;
      margin-bottom: 20px;
    }
  }

  &__button {
    display: block;
    margin-left: auto;
  }
}
