@import "@vars";

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  padding: 0 28px 28px 28px;
  border-radius: 12px;
  outline: none;

  width: 100%;
  max-height: 90vh;

  overflow: auto;

  &.mx {
    max-width: 1400px;
  }

  &.md {
    max-width: 850px;
  }

  &.lg {
    max-width: 1100px;
  }

  &.sm {
    max-width: 576px;
  }

  &__header {
    position: sticky;
    top: 0;
    left: -28px;
    right: -28px;
    width: calc(100% + 56px);
    z-index: 3;
    background: $white;
    padding: 28px 28px;
    margin: 0 -28px 28px;
    text-align: center;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 28px 15px 0px $white;

    font-size: 26px;
    line-height: 40px;

    &-title {
      padding-top: 28px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__close {
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    margin: 12px 0;
  }
}

.overlay {
  z-index: 20;
  position: fixed;
  inset: 0px;
  background: rgba($inactive, $alpha: 0.5);
}

@media (max-width: 1100px) {
  .modal {
    &.lg {
      max-width: 900px;
    }
  }
}

@media (max-width: 900px) {
  .modal {
    &__header {
      &-title {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 700px) {
  .modal {
    transform: none;
    max-height: unset;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;

    &__header {
      &-title {
        padding-top: 0;
      }
    }
  }
}
