@import "@vars";

.main {
  display: flex;
  margin-bottom: 30px;

  &__left {
    flex: 1 1 auto;
    margin-right: 30px;
  }

  &__right {
    position: sticky;
    top: -32px;
    height: fit-content;
    width: 410px;
    min-width: 410px;
    padding-top: calc($pageHeadedingHeight + $pageHeadedingMargin);
  }
}

@media (max-width: 1300px) {
  .main {
    &__right {
      min-width: 350px;
      width: 350px;
    }
  }
}

@media (max-width: 1100px) {
  .main {
    flex-direction: column;

    &__right {
      display: none;
    }

    &__left {
      margin-right: 0px;
    }
  }
}