@import "@vars";

.customer {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    background: $white;
    box-shadow: $shadow;
    border-radius: 12px;

    width: 494px;
    padding: 8px 8px 16px;

    text-align: start;
    line-height: 24px;

    &__heading {
        display: flex;
    }

    &__box {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 8px;
        transition: all .3s;

        &:hover {
            background: $alabaster;
            border-radius: 16px;
        }
    }

    &__name {
        font-weight: 700;
        text-transform: capitalize;
        margin-right: 8px;
    }

    &__info {
        background: $alabaster;
        padding: 8px 16px;
        margin-top: 12px;
        border-radius: 12px;
        text-align: center;
    }
}