@import "@vars";

.stats {
  display: flex;
  margin-bottom: 40px;

  &__block {
    flex: 1;

    h1 {
      height: $pageHeadedingHeight;
      margin-bottom: $pageHeadedingMargin;
    }

    &:first-child {
      margin-right: 34px;
    }

    &-card {
      min-height: 165px;
      padding: 36px 64px 49px;
      margin-top: 15px;

      p {
        margin-top: 16px;
        font-weight: 300;
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 768px) {
  .stats {
    flex-direction: column;
    gap: 34px;
    margin-bottom: 34px;
    &__block {
      h1 {
        height: 26px;
      }
      &:first-child {
        margin-right: 0;
      }
      &-card {
        min-height: 161px;
        padding: 34px;
        p {
          margin-top: 21.68px;
          margin-left: 8px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px;
        }
      }
    }
  }
}
