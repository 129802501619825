@import "@vars";

.table {
    padding-bottom: 20px;


    &__header {
        background: $gray-stroke;
        display: flex;
        line-height: 26px;
        color: $lightGray;
        gap: 11px;
        border-radius: 12px;
        margin-bottom: 25px;

        padding-left: 12px;

        &-item {
            flex: 1;
        }
    }

    &__row {
        display: flex;
        gap: 11px;
        padding-left: 12px;

        &-item {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        p {

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-list {
            display: flex;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;

            span {
                margin-right: 4px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 2px;
                }
            }
        }

        &-text {
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;

            &.underline {
                text-decoration: underline;
            }
        }

        ul {
            padding-left: 16px;
            font-size: 16px;
            line-height: 24px;
            list-style: disc;
        }

        &-add {
            font-size: 14px;
            line-height: 20px;
            color: $lightGray;
        }

    }

    &__info {
        padding-left: 16px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        background: $crm-background;
        border-radius: 12px;

        line-height: 24px;

        &-date {
            font-weight: 700;
            margin-right: 10px;
        }

        &-number {
            font-size: 16px;
        }

    }
}

.xlg {
    flex: 1.68;
}

.lg {
    flex: 1.33;
}

.sm {
    flex: 0.7;
}

.md {
    flex: 1;
}