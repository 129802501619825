@import "@vars";

.input {
  position: relative;
  cursor: text;

  min-height: 52px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transition: all 0.3s;

  border-bottom: 1px solid $gray;

  & span {
    color: $gray;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: -5px;
    transform: translateY(100%);

    color: $danger;
    font-size: 14px;
  }

  &:hover {
    & span {
      color: $dark;
    }

    border-color: $dark;
  }

  &__box {
    height: 36px;
    display: flex;
    align-items: center;

    &-extra {
      color: $dark;
      margin-right: 6px;
      white-space: nowrap;
    }
  }

  input {
    outline: none;
    border: none;
    font-size: 16px;
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    left: 0;
    top: 50%;
    transition: all 0.3s;
    pointer-events: none;
  }

  .clear {
    opacity: 0;
    visibility: hidden;
    z-index: -1;

    transition: all 0.3s;
  }

  &__btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &.error {
    input {
      border-color: $danger;
    }

    span {
      color: $danger;
    }
  }

  &.label {
    span {
      top: 5px;
      font-size: 12px;
      color: $lightGray;
    }

    &.error {
      span {
        color: $gray;
      }
    }

    .clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &.focused {
    border-color: $primary-color;

    input {
      caret-color: $primary-color;
    }
  }
}

.password_strength {
  position: absolute;
  left: 0;
  bottom: -5px;
  transform: translateY(100%);

  display: flex;
  align-items: center;
  gap: 8px;

  .strength_icon {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.yellow_icon {
  path {
    fill: $warning;
  }
}

.color_danger {
  color: $danger;
}
