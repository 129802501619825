@import "@vars";

.select {
    position: relative;
    z-index: 4;
    height: 52px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;

    &__btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray;
        height: 70%;

        &:hover {
            border-color: $dark;
        }

        &-icon {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            flex-shrink: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        & svg {
            transform: rotate(90deg);

            path {
                fill: $lightGray;
            }
        }
    }
}

.list {
    position: absolute;
    left: -15px;
    bottom: 0;
    z-index: 4;
    transform: translateY(100%);

    overflow: hidden;
    max-height: 0px;
    width: 250px;
    border-radius: 16px;
    box-shadow: $shadow;
    background: $white;

    display: flex;
    flex-direction: column;

    &.active {
        max-height: 428px;
    }


    &__box {
        overflow: auto;
        height: 100%;
        flex: 1 1 auto;

        &::-webkit-scrollbar-track {
            margin: 12px 0;
        }

        &-item {
            text-align: start;
            display: flex;
            align-items: center;
            white-space: nowrap;
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;

            transition: all 0.3s;

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 6px;
            }

            span {
                color: $gray;
            }

            &:hover {
                background: $iron;
            }
        }

        &-icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
        }
    }

    &__search {
        position: relative;
        padding: 0 16px;

        &.border {
            input {
                border-color: $gray;
            }
        }

        input {
            width: 100%;
            border: none;
            outline: none;
            height: 48px;
            border-bottom: 1px solid #fff;
            padding: 0 36px;
            color: $lightGray;
            font-size: 16px;

            &::placeholder {
                color: $gray;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.search {
                left: 16px;

            }

            &.arrow {
                cursor: pointer;
                right: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
}