@import "@vars";

.checkbox {
    display: flex;
    align-items: center;

    input {
        display: none;

        &:checked+label .checkbox__box {
            border: none;
            background: $dark url("../../assets/svg/outlinedCheck.svg") no-repeat center center;
        }
    }

    &.error {
        label {
            span {
                border-color: $danger;
            }
        }
    }

    &__box {
        display: block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: 1px solid $gray;
        border-radius: 4px;
        margin-right: 12px;

        transition: all .3s;
    }

    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
            flex-shrink: 0;
        }

    }
}