@import "@vars";

.form {
  margin-top: 20px;
  padding-bottom: 100px;

  &__message {
    padding: 16px;
    border-radius: 16px;
    text-align: center;
    background: $alabaster;
    line-height: 22px;
    margin-bottom: 28px;
  }

  &__input {
    margin-bottom: 20px;
    flex: 1;
  }

  &__row {
    display: flex;
    gap: 20px;
    position: relative;
    z-index: 3;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;
  }
  &__check {
    &.margin {
      margin-bottom: 10px;
    }

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.manage {
  display: flex;

  gap: 20px;
  justify-content: flex-end;
}

@media (max-width: 700px) {
  .form {
    &__input {
      margin-bottom: 10px;
    }

    &__card {
      padding: 24px 20px;
    }

    &__bottom {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10px;
    }

    &__check {
      margin-bottom: 10px;
    }

    &__button {
      width: 100% !important;
    }
  }
}
