@import "@vars";

.details {
    margin-top: 30px;

    &__title {
        margin-bottom: 20px;
    }

    &__card {
        padding: 30px 38px;
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 20px;

    }

    &__shadow {
        box-shadow: $shadow;
    }


    &__input {
        height: 72px;
        flex: 1;

        &.border {
            border-right: 1px solid $inactive;
        }

        &.margin {
            margin-bottom: 16px;
        }

        &.half {
            width: 50%;
        }

        &-btn {
            height: 72px !important;
        }
    }



    &__addBtn {
        display: flex;
        align-items: center;
        font-weight: 600;
        text-decoration: none !important;
    }


    &__block {
        margin-bottom: 30px;

        &-row {
            display: flex;
        }
    }
}

.address {
    display: flex;
    align-items: center;
    gap: 6px;

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    svg {
        cursor: pointer;
        width: 16px;
        height: 16px;

        rect {
            fill: $inactive
        }

        path {
            fill: $white
        }
    }

    &__number {
        width: 28px;
        height: 28px;
        border-radius: 20px;
        background: $primary-color;

        display: flex;
        align-items: center;
        justify-content: center;

        color: $white;
        font-weight: 500;
    }
}

.info {
    padding: 4px 30px;
    font-size: 16px;
    line-height: 24px;
    background: $alabaster;
    border-radius: 30px;
    width: fit-content;
}