@import "@vars";

.status {
  padding: 0 30px;
  border-radius: 30px;
  height: 20px;
  width: fit-content;

  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;

  display: flex;
  align-items: center;

  background: $success;
  color: $white;

  &.complete {
    background: $inactive;
    color: $dark;
  }

  &.suspended {
    background: $warning;
    color: $dark;
  }

  &.canceled {
    border: 1px solid $dark;
    color: $dark;
  }
  &.autorepeat {
    border: 1px solid $success;
    background: #fff;
    color: $success;
  }
}
