@import "@vars";

.error {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__message {
    width: 100%;
    background: $warning-info;
    padding: 16px;
    text-align: center;
    line-height: 22px;
    border-radius: 16px;
    margin-bottom: 28px;
  }
  a {
    color: $dark;
    &:hover {
      text-decoration: underline;
    }
  }
}
