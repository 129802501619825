@import "@vars";

.profile {
  &__title {
    height: $pageHeadedingHeight;
    margin-bottom: $pageHeadedingMargin;
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__actions {
    align-self: flex-end;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__cancel {
    padding: 0 24px !important;
    font-size: 16px !important;
  }
}

@media (max-width: 1100px) {
  .profile {
    &__btn {
      margin-top: 14px;
      padding: 16px 46px;
    }
    &__cancel {
      margin-top: 14px;
    }
  }
}
@media (max-width: 700px) {
  .profile {
    &__card {
      padding: 0;
      background: unset;
      box-shadow: unset;
      &::after {
        display: none;
      }
    }
  }
}
