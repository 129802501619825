@import "@vars";

.list {
    &__btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 4px 10px;
        margin: 0 -10px;
        width: fit-content;

        transition: all .3s;

        svg {

            opacity: 0;
            margin-left: 6px;
            transition: all .3s;
        }

        &:hover {
            background: $alabaster;

            svg {
                opacity: 1;
            }
        }
    }

    &__edit {
        cursor: pointer;
        text-decoration: underline;
        color: $lightGray;
        font-size: 14px;
    }

    &__clear {
        min-width: 16px;
        height: 16px;
        cursor: pointer;

        path {
            fill: $inactive;
            transition: all .3s;

        }

        &:hover {
            path {
                fill: $primary-color;
            }
        }

    }
}
@media (max-width: 1100px) {
    .list {
        display: none;
    }
}
