@import "@vars";

.payment {
    display: flex;
    flex-direction: column;


    &.right {
        align-items: flex-end;
    }

    &__top {
        display: flex;
        align-items: center;
        line-height: 24px;

        img {
            height: 22px;
            object-fit: contain;
            margin-right: 7px;
        }
    }

    &__icon {
        width: 32px;
        height: 22px;
        background: $white;
        box-shadow: $shadow;
        border-radius: 4px;
        margin-right: 7px;
    }

    &__type {
        color: $lightGray;
        font-size: 14px;
        line-height: 20px;
    }
}