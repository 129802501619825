.login {
    max-width: 450px;
    margin: auto;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 24px;

    }

    &__input {
        margin-bottom: 20px;
    }

    &__submit {
        text-transform: uppercase;
        margin-top: 32px;
    }
}