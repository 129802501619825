@import "@vars";

.info {
    border-radius: 12px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    text-align: start;

    &.full {
        width: 100%;
    }

    &.warning {
        background-color: $warning-info;
    }

    &.primary {
        background: $alabaster;

        svg {
            path {
                stroke: $success;
            }
        }
    }

    &.neutral {
        background-color: $alabaster;
        color: $lightGray;
    }

    &.borderless {
        border-radius: 0;
    }

    &.center {
        text-align: center;
    }

    &__icon {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
    }

    &__content {
        flex: 1;

        &-title {
            font-weight: 700;
            line-height: 1.4;

        }

        &-text {
            line-height: 1.4;
        }
    }


}