@import "@vars";

.react-calendar {
    border-radius: 16px;

    button {
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
    }

    &__navigation {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 12px;

        &__prev2-button,
        &__next2-button {
            display: none;
        }

        &__arrow {
            width: 32px;
            height: 32px;

            svg {
                path {
                    fill: $dark;
                }
            }
        }

        &__label {
            &__labelText {
                font-size: 12px;
                font-weight: 700;
            }
        }

        &__prev-button {
            transform: rotate(180deg);
        }


    }

    &__month {
        &-view {
            text-align: center;

            &__weekdays {
                margin-bottom: 12px;

                & abbr {
                    text-decoration: none;
                    font-size: 14px;
                    color: $lightGray;
                }
            }

            &__days__day {
                &--neighboringMonth {
                    abbr {
                        color: $gray;
                    }
                }
            }

        }
    }

    &__tile {
        color: $dark;
        width: 44px;
        height: 44px;
        padding: 2px;

        abbr {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 40px;
            height: 40px;
            margin: 0 auto;
            border-radius: 50%;

            transition: all .3s;

            &:hover {
                background: $alabaster;
            }

        }

        &:disabled {
            abbr {
                color: $gray;
                background: none !important;
            }
        }

        &--active {
            abbr {
                background: $primary-color;
                color: $white;
            }
        }


    }
}

@media (max-width: 450px) {
    .react-calendar {
        &__tile {


            abbr {


                width: 32px;
                height: 32px;

            }
        }
    }
}