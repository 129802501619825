@import "@vars";

.welcome {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;

  &__title {
    font-size: 14px;
    color: $lightGray;
    margin-bottom: 20px;
  }

  &.profile {
    &:hover {
      .welcome {
        &__info {
          &-image {
            cursor: pointer;

            &::after {
              background: rgba($gray, $alpha: .5);
            }
          }

          &-picture {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .bar {
    border-bottom: 1px solid $gray;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    &-right {
      margin-left: auto;
      text-align: right;
    }

    &-picture {
      opacity: 0;
      visibility: hidden;
      transition: all .3s;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &-image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 160px;
      border-radius: 2px;
      margin-right: 12px;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all .3s;

      }

      &.empty {
        background: $crm-background;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

      }
    }

    &-subname {
      color: $lightGray;
    }
  }

  &__total {
    padding: 10px;
    text-align: center;
    background: $alabaster;
    border-radius: 2px;
  }
  .logoUploadContainer {
    position: relative; /* Allows positioning the loader */
    display: inline-block; /* Inline-block to match the input size */
  }

  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3; /* Ensures the loader appears above everything */
  }
}