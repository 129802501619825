.collapse {
    &__title {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        margin-bottom: 30px;

        &.active {
            svg {
                transform: rotate(270deg);
            }
        }

        svg {
            width: 24px;
            height: 32px;
            transform: rotate(90deg);
            transition: all .3s;
        }
    }
}