@import "@vars";

.input {
    position: relative;
    height: 100%;
    padding: 11px 0 11px 28px;
    display: flex;
    align-items: center;

    &.half {

        width: 50%;
    }

    &.full {
        width: 100%;
    }

    &__wrapper {
        width: 100%;
    }

    &__label {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 4px;
        flex: 1;

        &.error {
            color: $danger;
        }
    }


    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 20px;
        width: fit-content;
        white-space: nowrap;
        border-left: 1px solid $dark;
        padding-right: 11px;
        font-size: 13px;
        font-weight: 600;

        svg {
            width: 44px;
        }

    }

    input {
        width: 100%;
        border: none;
        outline: none;
        flex: 1;

        font-size: 16px;
        font-weight: 400;

        &::placeholder {
            color: $gray;
        }
    }

    &:not(:first-child) {
        padding-left: 40px;
        border-left: 1px solid #C6CBD4;
    }

}

.select {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    background: $white;
    box-shadow: $shadow;
    border-radius: 0 0 8px 8px;

    max-height: 0;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    transition: all 0.3s;

    // &.top {
    //     bottom: unset;
    //     top: 0;
    //     transform: translateY(-100%);
    //     border-radius: 8px 8px 0 0;
    // }

    &.show {
        max-height: 448px;
    }

    &__list {
        overflow: auto;
        height: 100%;
        flex: 1 1 auto;

        &-item {
            cursor: pointer;
            font-size: 16px;
            line-height: 20px;
            padding: 10px 16px;

            transition: all 0.3s;

            &:hover {
                background: $iron;
            }
        }
    }

}

.autocomplete {
    position: relative;
    width: 100%;
    height: 100%;
}