@import "@vars";

.calendar {
    background: $white;
    box-shadow: $shadow;
    padding: 8px;
}

.dayTile {
    abbr {
        color: $dark !important;
        background: transparent !important;

        &:hover {
            background: $alabaster !important;
        }
    }

    &:disabled {
        abbr {
            color: $gray !important;
        }
    }

    &.activeDay {
        abbr {
            background: $primary-color !important;
            color: $white !important;
        }
    }
}