@import "@vars";

.list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
        cursor: pointer;
        margin-right: 20px;
        display: flex;
        align-items: center;

        font-size: 22px;
        font-weight: 600;

        &.active {
            color: $primary-color;
        }

        svg {
            cursor: pointer;
            margin-left: 10px;

            path {
                fill: $inactive;
            }
        }
    }
}