@import "@vars";

.zone {
    border-radius: 12px;
    height: 96px;
    border: 1px dashed $lightGray;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        border-color: $primary-color;
    }

    p {
        font-size: 26px;

        span {
            cursor: pointer;
            color: $primary-color;
        }
    }
}