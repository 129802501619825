@import "@vars";

.agree {
    padding: 34px 30px;
    text-align: center;

    font-size: 26px;

    &__wrapper {
        width: fit-content;
        max-width: 1016px;
        margin: 0 auto;
    }

    &__buttons {
        margin-top: 20px;
        display: flex;

        &--item {
            flex: 1;
            margin: 0 10px;
        }
    }


}