@import "@vars";
@import "@styles/shared/dots.scss";

@mixin color-style ()
{
  &:disabled,
  &.disabled {
    border-color: transparent;
    background: $gray;
  }

  &:focus {
    outline: 1px solid $black;
  }
}

.button {
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  padding: 0 24px;
  border-radius: 2px;
  font-family: $primary-font;
  font-weight: 600;
  height: 48px;
  color: $white;
  cursor: pointer;
  transition: all 0.2s;

  &.text-loading {
    &::after {
      content: " .";
      animation: dots 1s steps(5, end) infinite;
    }
  }

  &.primary {
    background: $grass-gradient;

    @include color-style();
  }

  &.primary-no-gradient {
    background: $primary-color;

    @include color-style();
  }

  &.none {
    background: none;
    color: $dark;
    text-decoration: underline;
    padding: 0;
    height: unset;
  }

  &.ghost {
    background: none;
    box-sizing: border-box;
    border: 1px solid $black;
    color: $black;

    span {
      background-color: $primary-color;
    }

    &:hover:not(:active):not(:disabled) {
      border: 1px solid $primary-color;
      color: $primary-color;
    }

    &:active {
      background: rgba($color: #222, $alpha: 0.05);
    }

    &:focus {
      outline: 1px solid $black;
    }

    &:disabled,
    &.disabled {
      background: none;
      border-color: $gray;
      color: $gray;
    }
  }

  &.full {
    width: 100%;
  }

  &.auto {
    width: auto;
  }

  &.sm {
    width: 97px;
  }

  &.md {
    width: 135px;
  }

  &.h-esm {
    font-size: 12px;
    height: 34px;
  }

  &.h-sm {
    font-size: 13px;
    line-height: 16px;
    height: 48px;
  }

  &.h-md {
    font-size: 16px;
    line-height: 19px;
    height: 60px;
  }

  &.rounded-none {
    border-radius: 0px;
  }

  &.rounded-sm {
    border-radius: 6px;
  }

  &.rounded-md {
    border-radius: 12px;
  }

  &.rounded-lg {
    border-radius: 18px;
  }
}
