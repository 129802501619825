@import "@vars";

.list {
  display: flex;
  margin-bottom: $pageHeadedingMargin;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &.unset {
    overflow: unset;
  }

  &__item {
    cursor: pointer;
    padding: 3px 30px;

    font-size: 16px;
    line-height: 26px;
    color: $dark;
    white-space: nowrap;

    background: $crm-background;
    border-radius: 30px;

    transition: all 0.3s;

    &.ghost {
      padding: 3px 16px;
      background: $white;
      border: 1px solid $dark;
      color: $dark;

      &.active {
        color: $white;
        background: $dark;
      }
    }

    &:not(&.disable):hover {
      background: $alabaster;
      color: $dark;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.active {
      background: $primary-color;
      color: $white;
    }
  }
}

@media (max-width: 1100px) {
  .list {
    overflow: scroll;
  }
}


@media (max-width: 700px) {
  .list {
    &__item {
      &-popup {
        display: none;
      }

      &:not(&.disable):hover {
        background: $primary-color;
        color: $white;
      }
    }
  }
}