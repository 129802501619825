@import "@vars";

.select {
    flex: 1;
    height: 100%;

    &__btn {
        cursor: pointer;
        height: 100%;
        padding: 11px 0 11px 28px;
        position: relative;
        background: $white;

        display: flex;
        flex-direction: column;
        justify-content: center;

        &-arrow {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }

        &-label {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        &-value {
            font-size: 16px;
            font-weight: 400;
            color: $gray;

            &.active {
                color: $dark;
            }
        }

        &.border {
            border-left: 1px solid $inactive;
        }
    }

    &__list {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;
        background: $white;
        border-radius: 12px;
        box-shadow: $shadow;

        max-height: 0;
        overflow: auto;

        transition: max-height .3s;

        &.bottom {
            bottom: -5px;
            transform: translateY(100%);
        }

        &.top {
            top: -5px;
            transform: translateY(-100%);
        }

        &--item {
            padding: 10px 20px;
            transition: all .3s;
            text-align: start;
            &:hover {
                background: $inactive;
            }

            &.active {
                background: $primary-color;
                color: $white;
            }
        }

        &.active {
            max-height: 300px;
        }
    }

}