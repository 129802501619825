@import "@vars";

.container {
  display: none;
}
@media (max-width: 1100px) {
  .container {
    display: block;
    .item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #b0b0b0;
      &__left {
        padding: 6px 0;
        margin-bottom: 18px;
      }
      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      &__date {
        color: $lightGray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      &__invoice {
        color: $primary-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 24px;
      }
      &__right {
        padding-top: 6px;
      }
      &__amount {
        margin-top: 18px;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
      }
      &__status {
        color: $lightGray;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
