@import "@vars";

.switch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.reverse {
        flex-direction: row-reverse;
        gap: 11px;
    }

    &__title {
        font-size: 18px;
        font-weight: 300;
    }

    &__btn {
        cursor: pointer;
        position: relative;
        display: block;
        width: 54px;
        height: 32px;
        border-radius: 16px;
        background: $iron;

        transition: all .3s;

        span {
            position: absolute;
            display: block;
            background: $white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            left: 2px;
            top: 2px;

            transition: all .3s;

        }

    }

    &__input {
        display: none;

        &:checked {
            &+label {
                background: $success;

                span {
                    left: calc(50% - 3px);
                }
            }
        }
    }
}