@import "@vars";

.conditions {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;

    &__suptitle {
        font-size: 14px;
        color: $lightGray;
    }

    &__bar {
        margin: 20px 0;
        height: 1px;
        background: $gray;
    }

    ul {
        margin-bottom: 20px;
    }

    &__note {
        background: $alabaster;
        padding: 16px;
        border-radius: 16px;
        margin-top: 6px;
    }
}