@import "@vars";

.header {
  position: relative;
  z-index: 5;

  box-shadow: $shadow;

  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 74px;
    max-width: 100% !important;
    padding-right: 0 !important;
  }


  &__menu {
    display: flex;
    margin: 0 auto;

    & a {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      height: 34px;
      margin: 0 4px;
      font-size: 12px;
      line-height: 26px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      color: $dark;
      border-radius: 20px;
      transition: all 0.3s;

      svg {
        margin-right: 8px;
      }

      &.active {
        color: $white;
        background: $dark;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
  }

  &__profile {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    background: $primary-color;
    height: inherit;
    width: 250px;
    padding-right: 36px;

    &-name {
      color: $white;
    }

    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $white;
      margin-left: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__popup {
    padding: 28px 0;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 11px;
    transform: translate(-36px, 100%);
    background: $white;
    box-shadow: $shadow;
    border-radius: 12px;

    transition: all .3s;

    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &-item {
      display: block;
      cursor: pointer;
      color: $dark;
      line-height: 22px;
      padding: 6px 28px;
      width: 100%;
      transition: all .3s;

      &:hover {
        background: $alabaster;
      }
    }
  }
}

.mobile {
  display: none;
}

@media (max-width: 1200px) {
  .header {
    &__profile {
      width: 230px;
    }

    &__logo {
      width: 150px;
    }
  }
}

@media (max-width: 1130px) {
  .container {
    padding-right: 0;
  }

  .mobile {
    display: block;
  }

  .header {
    &__box {
      padding-right: 20px !important;
    }

    &__profile {
      display: none;
    }

    &__popup {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;

      background: $white;
      box-shadow: $shadow;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: unset;

      // transform: translateX(-100%);
      width: 285px;
    }

    &__menu {
      display: none;

      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      padding: 28px 28px 16px;
      border-bottom: 1px solid $gray;

      & a {
        margin: 0 0 16px 0;
      }
    }

    &__profile {
      height: 74px;
      width: 100%;
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding-left: 28px;

      &-avatar {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    &__list {
      display: flex;
      display: none;
      flex-direction: column;
      padding-left: 28px;
      padding-top: 40px;

      a {
        font-size: 12px;
        font-weight: 500;
        line-height: 26px;
        color: $dark;
        text-transform: uppercase;

        padding: 4px 16px;
        margin-bottom: 20px;
      }
    }

    &__link {
      text-decoration: underline;
      color: $lightGray;
      font-size: 14px;
      margin-bottom: 14px;
    }

    &__btn {
      cursor: pointer;

      span {
        display: block;
        width: 18px;
        height: 2px;
        background: $dark;
        border-radius: 2px;
        margin: 3px 0;
      }
    }

    &__logo {
      margin: 0 auto;
    }
  }
}
