@import "@styles/shared/variables.scss";

.footer {
  border-top: 1px solid $gray;

  &__box {
    height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    display: flex;

    &-item {
      cursor: pointer;
      font-size: 12px;
      line-height: 26px;
      color: $dark;
      text-transform: uppercase;
      margin-right: 40px;
    }
  }

  &__btn {
    border-radius: 20px;
  }
}

@media (max-width: 700px) {
  .footer {
    &__menu {
      &-item {
        display: none;
        margin-right: 12px;

        &.mobile {
          display: block;
        }
      }
    }
  }
}