@import "@vars";

.reviews {
  &__no-data {
    text-align: center;
  }
}

.review {
  display: flex;
  border-color: $gray;
  border-style: solid;
  padding: 20px 0;

  transition: all .3s;

  &.hover {
    background: $alabaster;
  }

  &__left {
    min-width: 175px;
    margin-right: 44px;
  }

  &__right {
    flex: 1 1 auto;
  }

  &__action {
    background: $crm-background;
    padding: 20px 28px;
    border-radius: 12px;

    display: flex;
    gap: 10px;

    font-size: 16px;
    line-height: 24px;

    &-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: $primary-color;
      color: $white;
      font-size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;

    &-name {
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: $lightGray;
    }
  }

  &__date {
    color: $lightGray;
    font-size: 14px;
    line-height: 24px;
  }

  &__stars {
    svg {
      width: 20px;
      height: 20px;
    }

    b {
      font-size: 18px;
      margin-left: 13px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
  }
  .mobile_stars {
    display: none;
  }
}

@media (max-width: 768px) {
  .review {
    padding: 16px 10px;
    flex-direction: column;
    &__right {
      margin-top: 26px;
    }
    &__top {
      &-name {
        font-size: 16px;
        line-height: normal;
      }
    }
    &__stars {
      b {
        margin-left: 6px;
      }
    }
    &__number {
      font-size: 16px;
      line-height: 24px;
    }
    &__date {
      line-height: 20px;
    }
    .mobile_stars {
      margin-top: 23px;
      display: flex;
      justify-content: flex-end;
    }
    &__action {
      margin-top: 20px;
    }
  }
}
