.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 542px;
  margin: 0 auto;

  font-size: 20px;
  line-height: 24px;

  &__title {
    font-weight: 700;
  }

  &__subtitle {
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .empty {
    &__subtitle {
      padding: 0 20px;
    }
  }
}
