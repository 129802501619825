@import "@vars";

.cities {
    top: 30px !important;
    transform: translate(-50%, 0) !important;
    min-height: 50%;
    height: 90vh;
    overflow: initial !important;
    padding-bottom: 0 !important;


    display: flex;
    flex-direction: column;

    &__header {
        box-shadow: none !important;
    }

    &__title {
        margin-bottom: 20px;
        font-size: 50px;
        font-weight: 800;
    }

    &__subtitle {
        font-size: 32px;
        font-weight: 700;
        line-height: 60px;
        color: $primary-color;
    }

    &__box {
        display: flex;
        flex: 1;
    }

    &__map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        &-wrapper {
            position: relative;
            flex: 1;

        }

        path {
            outline: none !important;
        }
    }

    &__list {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-top: 20px;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &-wrapper {
            position: relative;
            height: 100%;
            width: 258px;
        }

    }

    &__select {
        text-transform: uppercase;
        padding: 0 28px;
        margin: 0 -28px;
        flex-shrink: 0;

        &.regions {
            margin-top: 20px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        font-size: 22px;
        font-weight: 600;

        transition: all .3s;

        &-icon {
            margin-right: 10px;

        }

        &:hover {
            cursor: pointer;
            color: $primary-color;
        }
    }



}


@media (max-width: 700px) {
    .cities {
        top: 0 !important;
        transform: none !important;
    }
}