.byCard {
  &__list {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  &__download {
    cursor: pointer;
  }
}
@media (max-width: 1100px) {
  .byCard {
    &__list {
      margin-bottom: 10px;
    }
  }
}
