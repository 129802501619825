@import "@vars";

.mobile_container {
  display: none;
}

.mobile_title {
  display: none;
}

.header {
  display: flex;

  &__inner {
    position: relative;

  }

  &__search {
    background: none !important;
    padding: 0 !important;
  }
}

@media (max-width: 1100px) {


  .mobile_title {
    display: block;
    line-height: 32px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .mobile {
    &__select {
      margin: 0 -20px 20px;
      padding: 0 20px;
    }
  }

}

@media (max-width:900px) {
  .header {
    &__filter {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}