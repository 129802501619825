@import "@vars";

.stars {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  b {
    font-size: 22px;
  }
}
@media (max-width: 1100px) {
  .stars {
    gap: 7px;
    svg {
      width: 30px;
      height: 28.37px;
    }
  }
}
