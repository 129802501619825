@import "@vars";

.feedbacks {
  &__title {
    margin-bottom: 16px;
  }

  &__box {
    position: relative;
    // height: 321px;
    background: url("../../assets/images/Booking/map.jpg");
    padding-left: calc((100% - 1750px) / 2);
    padding: 34px 0;

    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
  }

  &__empty {
    margin-left: $containerPadding;

  }

  &__arrows {
    display: flex;
    flex-direction: column;
    margin-left: $containerPadding;
    margin-right: 50px;

    svg {
      cursor: pointer;
      margin: 8px 0;

      &.left {
        transform: rotate(180deg);
      }

      path {
        transition: all .3s;
      }

      &:hover {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}

.feedback {
  cursor: pointer;
  position: relative;
  min-width: 410px;
  max-width: 410px;
  height: 250px;
  border-radius: 4px;
  box-shadow: $shadow;
  background: $white;
  padding: 23px 49px;
  margin-right: 30px;

  display: flex;
  flex-direction: column;

  transition: opacity .3s, transform .3s, max-width .3s, min-width .3s, padding .3s, margin .3s;
  transition-delay: 0ms, 0ms, .3s, .3s, .3s, .3s;

  &.lg {
    height: 317px;

    & .feedback {
      &__text {
        -webkit-line-clamp: 2;
      }
    }
  }

  &.empty {
    padding-left: 34px;
  }

  &.hide {
    transform: translateY(-100%);
    opacity: 0;
    min-width: 0;
    padding: 0;
    margin: 0;
    max-width: 0;

  }

  &.show {
    transition: opacity .3s, transform .3s, min-width .3s, padding .3s, margin .3s;
    transition-delay: .3s, .3s, 0s, 0s, 0s, 0s;
  }

  &__stars {
    margin-bottom: 16px;
  }

  &__text {
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    margin-bottom: 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__name {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 32px;
    text-transform: capitalize;
  }

  &__info {
    font-size: 14px;
    line-height: 20px;
    color: $lightGray;
  }

  &__empty {
    text-align: center;
    padding-top: 35%;

    svg {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -50%);
    }

    &-title {
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 5px;
      font-weight: 600;
    }

    &-info {
      font-size: 16px;
      line-height: 24px;
      color: $lightGray;
    }

  }

  &__action {
    background: $crm-background;
    padding: 10px 28px;
    border-radius: 12px;
    margin: 10px -33px 0;

    display: flex;
    gap: 10px;

    font-size: 16px;
    line-height: 24px;

    &-avatar {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: $primary-color;
      color: $white;
      font-size: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-text {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

@media (max-width: 700px) {
  .feedbacks {
    &__arrows {
      display: none;
    }
  }

  .feedback {
    min-width: calc(100vw - 40px);
    margin: 0 20px;
  }
}