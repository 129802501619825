@import "@vars";

.rate {
    background: $white;
    box-shadow: $shadow;
    padding: 28px;
    margin-bottom: 20px;
    border-radius: 12px;

    display: flex;

    &__left {
        width: 274px;
        margin-right: 30px;

        display: flex;
        flex-direction: column;

        &-image {
            height: 190px;
            object-fit: contain;

        }

        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

        }
    }

    &__check {
        margin-bottom: 16px;
    }

    &__footing {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .limites {
        display: flex;
        align-items: center;

        &__item {
            display: flex;
            align-items: center;

            margin-right: 6px;
            font-size: 14px;
            font-weight: 600;

            svg {
                margin-right: 6px;
            }
        }
    }

    &__content {
        position: relative;
        flex: 1;

        &-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 18px;
        }

        &-price {
            position: absolute;
            top: 0;
            right: 0;

            font-size: 22px;
            font-weight: 600;
        }


    }

    &__cars {
        margin-bottom: 30px;
        line-height: 19px;
        list-style-image: url("../../../../assets/svg/check.svg");
        list-style-position: inside;

        li {
            margin-bottom: 14px;
        }
    }

    &__seats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 6px;

        &-add {
            cursor: pointer;
            margin-right: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            height: 66px;
        }

        &-select {
            padding: 10px 25px 10px 10px;
            width: 100%;
            height: 56px;
            background: none;

            font-size: 18px;
            line-height: 24px;
            font-weight: 300;
        }

        &-item {
            position: relative;
            height: inherit;
            width: 146px;
            border-radius: 12px;
            padding-right: 24px;
            margin-right: 12px;
            margin-bottom: 10px;

            &>svg {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                path {
                    fill: $inactive
                }
            }
        }

        &-input {
            margin-bottom: 20px;
        }
    }
}

.mobile {
    display: none !important;
}

@media (max-width: 700px) {
    .mobile {
        display: block !important;
    }

    .rate {
        &__left {
            display: none;

            &-image {
                display: none;
                height: 120px;
                margin: 0 auto;


            }
        }

        &__seats {
            flex-direction: column;
            height: unset;
            align-items: flex-start;
            gap: 20px;
            margin: 20px 0;

            &-item {
                width: 100%;
            }

            &-select {
                height: 56px;
            }
        }

        &__footing {
            flex-wrap: wrap;
        }

        &__content {
            &-btn {
                width: 100% !important;
                margin-top: 20px;
            }
        }
    }
}