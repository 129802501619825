@import "@vars";

.picker {
    position: relative;
    cursor: pointer;
    background: $white;
    height: 100%;
    flex: 1;
    border-right: 1px solid $inactive;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__btn {
        padding-left: 20px;
    }

    &__calendar {
        width: 320px;

        &-inner {
            position: absolute;
            z-index: 2;
            left: 0;
            opacity: 0;
            visibility: hidden;

            transition: opacity, visibility .3s;

            &.bottom {
                bottom: -5px;
                transform: translateY(100%);
            }

            &.top {
                top: -5px;
                transform: translateY(-100%);
            }

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }


    }

    b {
        text-transform: uppercase;
        margin-bottom: 4px;
        font-size: 14px;

        &.error {
            color: $danger;
        }


    }

    p {
        font-size: 16px;
        line-height: 24px;

        &.label {
            color: $gray;
        }
    }

    &>svg {
        position: absolute;
        right: 12px;
        top: 50%;
        transform-origin: center;
        transform: rotate(90deg) translateX(-50%);
    }
}

@media (max-width: 450px) {
    .picker {
        &__calendar {
            width: 280px;
        }
    }

}