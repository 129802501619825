@import "@vars";

.order {
    display: flex;
    flex-direction: column;
    overflow: unset;
    height: 100%;

    &__title {
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 20px;
        text-align: center;
    }

    &__subtitle {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;

        &.marginTop {
            margin-top: 28px;
        }
    }

    &__box {
        display: flex;
        margin-bottom: 20px;
        flex: 1;
        gap: 20px;

        &-item {
            flex: 1;

            display: flex;
            flex-direction: column;
        }
    }

    &__row {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $gray-stroke;
        padding: 11px 0;

        order: 4;

        &.first {
            cursor: unset;
            order: 0
        }

        &.hover {

            background: $alabaster;
        }

        &-item {
            line-height: 24px;



            &.bold {
                font-weight: 700;
            }
        }
    }

    &__date {
        height: 72px;
        display: flex;
        box-shadow: $shadow;
        margin-bottom: 10px;
        border-radius: 0 12px 12px 0;

        &-search {
            flex: 1;
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                cursor: pointer;
            }
        }
    }

    &__list {
        &-wrapper {
            position: relative;
            flex: 1;
        }

        &-empty {
            margin: 15px 0;
            text-align: center;
        }

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-right: 10px;
    }
}

.marker {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid orange;
    background: rgba($color: orange, $alpha: .5);
    border-radius: 50%;
    transform: translate(-50%, -50%);

    transition: all .3s;

    &__car {
        width: 32px;
        height: 32px;

        & img {
            transform: translateY(-30px);
        }
    }

    &.hovered,
    &:hover {
        z-index: 1;
        border: 1px solid green;
        background: rgba($color: green, $alpha: .5);
    }
}

@media (max-width: 900px) {
    .order {
        padding: 0 20px !important;
        max-height: unset !important;
        border-radius: 0 !important;
        height: 100vh;

        &__select {
            overflow: auto !important;
            margin: 20px -20px;
            padding: 0 20px;
        }

        &__subtitle {
            margin-bottom: 0;

            &.marginTop {
                display: none;
            }
        }

        &__header {
            margin-bottom: 0;
            box-shadow: none;
        }

        &__box {
            flex-direction: column;
            margin-bottom: 0;

            &-item {
                flex: 1;

                &.list {
                    overflow: auto;
                    padding-right: 10px;
                }

                &:first-child {
                    flex: 0.7;
                    margin: 0 -20px;
                }
            }
        }

        &__list {
            padding-bottom: 10px;
            padding-right: 0;
            overflow: visible;

        }

        &__btn {
            display: none;
        }

        &__date {

            display: none;
        }
    }

}

@media (max-width: 500px) {
    .order {
        &__header {
            font-size: 20px;
        }
    }
}